<template>
    <div>
        <div class="row" v-if="btnIndex == 1">
            <div class="col-12 col-lg-7">
                <b-card :header="$t('course_detail_title')" class="soft">
                    <div class="row">
                        <div class="col-12">
                            <b-form-group :label="$t('faculty')">
                                {{ getLocaleText(viewData, 'faculty_name') }}
                            </b-form-group>
                        </div>

                        <div class="col-12">
                            <b-form-group :label="$t('program')">
                                {{ getLocaleText(viewData, 'program_name') }}
                            </b-form-group>
                        </div>

                        <div class="col-6">
                            <b-form-group :label="$t('course_code')">
                                {{ getObjectValue(viewData, 'course_code') }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6">
                            <b-form-group :label="$t('course_name')">
                                {{ getLocaleText(viewData, 'course_name') }}
                            </b-form-group>
                        </div>
                        <div class="d-flex col-12" style="gap:16px">
                            <b-form-group :label="$t('credit')">
                                {{ getObjectValue(viewData, 'credit') }}
                            </b-form-group>

                            <b-form-group :label="$t('ects_credit')">
                                {{ getObjectValue(viewData, 'ects_credit') }}
                            </b-form-group>

                            <b-form-group :label="$t('theoretical')">
                                {{ getObjectValue(viewData, 'theoretical_credit') }}
                            </b-form-group>

                            <b-form-group :label="$t('practical')">
                                {{ getObjectValue(viewData, 'practical_credit') }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group :label="$t('section')">
                                <b-form-select v-model="section"
                                               :options="sectionOptions"/>
                            </b-form-group>
                        </div>
                        <div class="col-12 col-sm-6 col-md-12">
                            <b-form-group :label="$t('semester')">
                                <semesters-selectbox v-model="semester"/>
                            </b-form-group>
                        </div>
                    </div>
                </b-card>
            </div>
            <div class="col-12 col-lg-5">
                <b-card :header="$t('staff')" class="soft">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                        <b-form-group :label="$t('teaching_staff')">
                            {{ getObjectValue(viewData, 'instructor') }}
                        </b-form-group>
                    </div>
                </b-card>
                <b-card :header="$t('processes')" class="soft">
                    <b-card-body class="d-flex flex-column flex-sm-row flex-wrap" style="gap:8px">
                        <b-button variant="outline-primary" style="line-height:1" @click="studentListWithPhoto">
                            {{ $t('student_list_with_photo').toUpper() }}
                        </b-button>
                        <b-button variant="outline-primary" style="line-height:1" @click="attendanceEntry">{{
                                $t('prep_attendance').toUpper()
                            }}
                        </b-button>
                        <b-button variant="outline-primary" @click="gradeEntry">{{
                                $t('grade_entry').toUpper()
                            }}
                        </b-button>
                        <b-button variant="outline-primary" @click="studentList">{{
                                $t('student_list').toUpper()
                            }}
                        </b-button>
                        <b-button variant="outline-primary" @click="openDocuments">{{
                                $t('documents').toUpper()
                            }}
                        </b-button>
                        <b-button variant="outline-primary" @click="goLms">{{
                                $t('lms').toUpper()
                            }}
                        </b-button>
                    </b-card-body>
                </b-card>
            </div>
        </div>
        <students :courseId="id"
                  :semesterId="semester"
                  :sectionNo="sectionNo"
                  @back="courseDetails"
                  v-if="btnIndex == 2"/>
        <students-with-photo :courseId="id"
                             :semesterId="semester"
                             :sectionNo="sectionNo"
                             :coverLetter="viewData"
                             @back="courseDetails"
                             v-if="btnIndex == 3"/>
        <CommonModal ref="documents" size="lg" :onHideOnlyX="true" @bHideModalHeaderClose="formClear()">
            <template v-slot:CommonModalTitle>{{ $t('documents') }}</template>
            <template v-slot:CommonModalContent v-if="process=='documents'">
                <b-row v-if="documents && documents.length>0">
                    <b-col cols="12" md="6"
                           v-for="(document, index) in documents"
                           :key="index"
                    >
                        <b-dropdown
                            v-if="checkPermission(document.permission)"
                            class="mb-2"
                            block
                            split
                            split-variant="outline-dark"
                            variant="dark"
                            right
                            :text="document.text.toUpper() || ''"
                        >

                            <b-dropdown-item @click="document.callback(lang)"
                                             v-for="(lang,langKey) in document.language"
                                             :key="'lang'+langKey">
                                {{ $t(lang) }}
                            </b-dropdown-item>

                        </b-dropdown>
                    </b-col>
                </b-row>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import SectionService from "@/services/SectionService";
import Students from "./Students"
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import StudentsWithPhoto from "./StudentsWithPhoto";
import CommonModal from "@/components/elements/CommonModal.vue";
import i18n from "@/plugins/i18n";

import StudentDocumentService from "@/services/StudentDocumentService";
import StudentCourseService from "@/services/StudentCourseService";

import {EventBus} from "@/main";
import showErrors from "@/helpers/showErrors";
import AttendanceService from "@/services/AttendanceService";
import qs from "qs";


export default {
    components: {
        CommonModal,
        Students,
        SemestersSelectbox,
        StudentsWithPhoto
    },
    props: {
        id: {
            default: null,
            type: Number
        },
        semester_id: {
            default: null
        }
    },
    data() {
        return {
            form: {},
            viewData: [],
            section: null,
            sectionOptions: [],
            btnIndex: 1,
            semester: null,
            sectionNo: null,
            process: null,
            documents: [
                {
                    text: i18n.t('attendance_list'),
                    class: 'ri-article-line',
                    permission: 'studentdocument_attendancelist',
                    language: [this.$t('student_number_filter'), this.$t('student_name_filter')],
                    callback: (lang) => {
                        if (lang === 'Öğrenci Numarasına Göre Sırala') {
                            if (this.section != null) {
                                this.form.sort = 'student_number'
                                StudentDocumentService.downloadAttendanceList(this.section, this.form)
                                    .then(response => {
                                        let fileName = i18n.t('print_roster') + '-' + lang + '.pdf'
                                        EventBus.$emit('pdfViewerModalShow', {
                                            pdfFileData: response.data,
                                            pdfFileName: fileName
                                        });
                                    })
                                    .catch(e => {
                                        showErrors(e, null, true)
                                    })
                            } else {
                                this.$toast.error(this.$t('entry_section'))
                            }
                        } else if (lang === 'Öğrenci İsmine Göre Sırala')
                            if (this.section != null) {
                                this.form.sort = 'name'
                                StudentDocumentService.downloadAttendanceList(this.section, this.form)
                                    .then(response => {
                                        let fileName = i18n.t('print_roster') + '-' + lang + '.pdf'
                                        EventBus.$emit('pdfViewerModalShow', {
                                            pdfFileData: response.data,
                                            pdfFileName: fileName
                                        });
                                    })
                                    .catch(e => {
                                        showErrors(e, null, true)
                                    })
                            } else {
                                this.$toast.error(this.$t('entry_section'))
                            }
                    }
                },
                {
                    text: i18n.t('print_weekly_roste'),
                    class: 'ri-article-line',
                    language: ['tr'],
                    callback: (lang) => {
                        if (this.section != null) {
                            StudentDocumentService.downloaWeeklydAttendanceList(this.section)
                                .then(response => {
                                    let fileName = i18n.t('print_weekly_roste') + '-' + lang + '.pdf'
                                    EventBus.$emit('pdfViewerModalShow', {
                                        pdfFileData: response.data,
                                        pdfFileName: fileName
                                    });
                                })
                                .catch(e => {
                                    showErrors(e, null, true)
                                })
                        } else {
                            this.$toast.error(this.$t('entry_section'))
                        }
                    }
                },

                {
                    text: i18n.t('excel'),
                    class: 'ri-article-line',
                    permission: 'studentcourse_instructorenteredlettergradeexcelexport',
                    language: [this.$t('download')],
                    callback: () => {
                        if (this.section != null && this.section != 'undifined') {
                            StudentCourseService.instructorLetterGradeExcelReport(this.section)
                                .then(res => {
                                    this._downloadFile(res, this.$t('instructor_letter_grade_excel_export') + '.xlsx')
                                })
                                .catch(e => {
                                    showErrors(e, null)
                                })
                        } else {
                            this.$toast.error(this.$t('section_message'))
                        }

                    }
                },

                {
                    text: i18n.t('absence_report'),
                    class: 'ri-article-line',
                    permission: 'attendance_getattendancereport',
                    language: ['download'],
                    callback: () => {
                        if (this.section != null) {
                            this.sectionOptions.forEach((item) => {
                                if (item.value == this.section) {
                                    this.sectionNo = item.text
                                }
                            })
                        }

                        const config = {
                            params: {
                                filter: {
                                    semester_id: this.semester_id,
                                    course_id: this.viewData.course_id,
                                    section: this.sectionNo,
                                    instructor_id: this.viewData.instructor_id
                                }
                            },
                            paramsSerializer: (params) => qs.stringify(params, {encode: false})
                        }
                        AttendanceService.report(config).then(response => {
                            this._downloadFile(response, this.$t('absence_report') + '.xlsx')
                        }).catch(e => {
                            showErrors(e, null)
                        })
                    }
                }
            ]
        }
    },
    metaInfo() {
        return {
            title: this.$t("instructor_courses_title")
        }
    },
    methods: {
       async goLms() {
           if (this.section != null) {
               await  this.$router.push('/lms/'+this.id)
               this.parameter()
           }
           else {
               this.$toast.error(this.$t('section') + ' ' + this.$t('select'))
           }


        },
        parameter(){
            EventBus.$emit("parameter", {
                semester: this.semester_id,
                courseId: this.id,
                section: this.section,
                courseName:this.viewData.course_name,
                courseCode:this.viewData.course_code
            });
        },
        getData(courseId) {
            this.btnIndex = 1
            this.sectionOptions = []
            const config = {
                params: {
                    course_id: courseId,
                    semester_id: this.semester_id
                },
            }

            SectionService.instructorSections(config).then(response => {
                this.viewData = response.data.data[0]
                response.data.data.forEach(item => {
                    this.sectionOptions.push({value: item.section_id, text: item.section})
                })
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message))
            })

        },

        gradeEntry() {
            this.$router.push('/grade/entry/' + this.id)
        },

        attendanceEntry() {
            if (this.section != null) {
                this.$router.push('/attendance/entry/' + this.section)
            } else {
                this.$toast.error(this.$t('section') + ' ' + this.$t('select'))
            }
        },

        studentList() {
            if (this.semester != 0 && this.section != null) {
                this.sectionOptions.forEach((item) => {
                    if (item.value == this.section) {
                        this.sectionNo = item.text
                    }
                })
                this.btnIndex++

            } else {
                this.$toast.error(this.$t('semester') + ', ' + this.$t('section') + ' ' + this.$t('select'))
            }
        },

        studentListWithPhoto() {
            if (this.semester != 0 && this.section != null) {
                this.sectionOptions.forEach((item) => {
                    if (item.value == this.section) {
                        this.sectionNo = item.text
                    }
                })
                this.btnIndex = 3

            } else {
                this.$toast.error(this.$t('semester') + ', ' + this.$t('section') + ' ' + this.$t('select'))
            }
        },

        courseDetails() {
            this.btnIndex = 1
        },
        openDocuments() {
            this.process = 'documents';
            this.showModal();
        },
        formClear() {
            this.process = null;
            this.hideModal();
        },
        showModal() {
            this.$refs.documents.$refs.commonModal.show()
        },
        hideModal() {
            this.$refs.documents.$refs.commonModal.hide()
        }

    },

    created() {
        this.getData(this.id)
        this.semester = this.semester_id
    },

    watch: {
        id: {
            handler: function (val) {
                this.getData(val)
            }
        }
    }
};
</script>

