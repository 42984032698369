import Api from '@/services/Index';

const getAttendanceForSection = async (sectionId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/attendances/' + sectionId + '/weeks', formData);
}

const getSectionStudents = async (sectionId, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/sections/' + sectionId + '/students', formData);
}

const getAttendanceWithSectionAndWeek = async (sectionId, weekNumber, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/attendances/' + sectionId + '/' + weekNumber, formData);
}

const save = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/attendances/', formData);
}

const report = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/attendances/report', {...config,responseType:'arraybuffer'});
}

export default {
    getAttendanceForSection,
    getSectionStudents,
    getAttendanceWithSectionAndWeek,
    save,
    report
}
